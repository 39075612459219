export default {
  "action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Aktion"]), _normalize(["Aktionen"])])},
  "admin": {
    "user": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    }
  },
  "api-errors": {
    "ALREADY_PERMITTET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dazu ist bereits berechtigt!"])},
    "COMPANY_OR_NAME_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben sie ihren Namen und/oder ihre Firma an!"])},
    "Failed to fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Datenübertragung!"])},
    "GROUP_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Gruppe existiert bereits!"])},
    "INVALID_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Code!"])},
    "INVALID_INPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Eingabe"])},
    "INVALID_LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Login!"])},
    "INVALID_PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Zahlungsmethode!"])},
    "NOT_ENOUGH_STOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produkt hat nicht genügend Bestand!"])},
    "System error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemfehler!"])},
    "UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert"])}
  },
  "auth": {
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "labels": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
      "password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib dein Passwort ein"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "loginName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loginname"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "reset-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutze den dir gegebenen Code, um dein Password zurückzusetzen"])}
  },
  "business": {
    "sales": {
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf eintragen"])},
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf erfolgreich eingetragen"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspreis"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
      "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauft am"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäfte"])}
  },
  "colorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbcode"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "company": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziegler & Glas Dienstleistungen"])}
  },
  "cookies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten über dich, wie deine IP-Adresse. Wir teilen diese Daten auch mit Dritten. Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Webseite verwendet Cookies"])}
  },
  "dialog": {
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "createTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellen"])},
    "deleteText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Möchtest du diese/-s/-n"]), _normalize(["wirklich löschen?"])])},
    "deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigename"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler!"])},
  "home": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Services"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "workerarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterbereich"])}
  },
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
  "imprint": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum Angaben gemäß § 5 TMG"])}
  },
  "lastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzer Login"])},
  "locales": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])}
  },
  "orders": {
    "addBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres Produkt hinzufügen"])},
    "completeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung abschließen"])},
    "completeOrderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diese Bestellung wirklich abschließen, den Bestand abziehen und den Verkauf eintragen?"])},
    "designatedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitende Person"])},
    "history": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestell-History"])}
    },
    "payment": {
      "INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung"])},
      "PAYPAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal"])}
    },
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
    "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsstatus"])},
    "place": {
      "prodSel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte auswählen"])},
      "titleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich für eines oder mehrere Unserer Produkte entschieden? Das folgende Formular führt sie durch den Bestellprozess!"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])}
  },
  "permissions": {
    "all": {
      "createTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung hinzufügen"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung bearbeiten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Berechtigungen"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen bearbeiten"])},
    "group": {
      "createTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe hinzufügen"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Berechtigung"])},
    "rank": {
      "permissionLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungsstufe"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ränge"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])}
  },
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
  "privacy": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br /><br />Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br /><br />Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor."])}
  },
  "status": {
    "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
    "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
    "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "PAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
    "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
    "QUEUED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I. d. Warteschlange"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg!"])},
  "texts": {
    "AddSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "AdminUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Informationen über einen Benutzer"])},
    "AdminUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle registrierten Benutzer"])},
    "AllPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "CardServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Überblick unserer Dienstleistungen für Karten, Anhänger etc."])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
    "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "NotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gewünschte Seite konnte nicht gefunden werden!"])},
    "OpenOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen, I. d. Warteschlange & In Bearbeitung"])},
    "OrderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt & abgebrochen"])},
    "PermissionGroupsNRanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle erfolgreichen Verkäufe"])},
    "Unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist hierzu nicht berechtigt!"])},
    "UserSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "WarehouseProductStocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "WarehouseProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle angebotenen Produkte"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstempel"])},
  "titles": {
    "AddSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf eintragen"])},
    "AdminUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerseite"])},
    "AdminUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "AllPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Berechtigungen"])},
    "CardServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Services"])},
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziegler & Glas"])},
    "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "NotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "OpenOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Bestellungen"])},
    "OrderHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestell-History"])},
    "PermissionGroupsNRanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen und Ränge"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung aufgeben"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
    "Unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert"])},
    "UserSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Sessions"])},
    "WarehouseProductStocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenbestände"])},
    "WarehouseProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "unauthorized": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück auf die Startseite"])}
  },
  "user": {
    "session": {
      "blockText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du diesen Nutzer wirklich blocken?"])},
      "blockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User blocken"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Session"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Sessions"])}
    }
  },
  "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss akzeptiert werden"])},
    "code-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteht aus 5 Zeichen"])},
    "float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Eingave muss eine Dezimalzahl sein!"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail Adresse"])},
    "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl oder Spezialzeichen enthalten"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Eingabe muss eine Zahl sein!"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
    "password-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 8 Zeichen"])},
    "password-upper-and-lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Gross- und ein Kleinbuchstabe"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld wird benötigt"])},
    "toLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Eingabe darf maximal 200 Zeichen lang sein"])},
    "toLong250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Eingabe darf nicht länger als 250 Zeichen sein!"])},
    "toLong30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Eingabe darf nicht länger als 30 Zeichen betragen!"])},
    "toLong8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Eingabe darf nicht länger als 8 Zeichen sein!"])}
  },
  "warehouse": {
    "products": {
      "basePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundpreis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktbeschreibung"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])},
      "publicView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlich sichtbar?"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])}
    },
    "stock": {
      "minStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestbestand"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenbestände"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerhaus"])}
  }
}