import i18n from '@/i18n';
import moment from 'moment';

export class Utils {
  static getImgUrl (img) {
    try {
      return require('../assets/img/' + img);
    } catch (e) {
      return undefined;
    }
  }

  // static getBannerImages () {
  //   const imageFiles = require.context('../assets/img/banner', false, /\.(webp)$/);
  //   const images = [];
  //   imageFiles.keys().forEach(key => {
  //     images.push(imageFiles(key));
  //   });
  //   return images;
  // }

  static paginate (array, pageSize, pageNumber) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static roundN (value, digits) {
    const tenToN = 10 ** digits;
    return (Math.round(value * tenToN)) / tenToN;
  }

  static isEven (n) {
    return n % 2 === 0;
  }

  static formatNumber (number) {
    return new Intl.NumberFormat(i18n.global.locale).format(number);
  }

  static formatDate (date) {
    moment.locale(i18n.global.locale);
    const d = moment(date).format('dddd, LL');
    return d === 'Invalid date' ? date : d;
  }

  static formatDateTime (dateTime) {
    moment.locale(i18n.global.locale);
    const d = moment(dateTime).format('dddd, LLL');
    return d === 'Invalid date' ? dateTime : d;
  }

  static getLocaleMoment (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime);
  }

  static formatRelativeTime (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime).fromNow();
  }

  static formatMinutes (minutes) {
    const out = {};
    if (minutes > 60 * 24) {
      out.value = minutes / (60 * 24);
      out.unit = 'DAYS';
    } else if (minutes > 60) {
      out.value = minutes / 60;
      out.unit = 'HOURS';
    } else {
      out.value = minutes;
      out.unit = 'MINUTES';
    }

    out.value = this.roundN(out.value, 2);
    return out;
  }

  static getBackendUrl () {
    return process.env.VUE_APP_BACKEND_URL;
  }

  static validationRequired (v) {
    return !!v || i18n.global.t('validation.required');
  }

  static validationAccepted (v) {
    return !!v || i18n.global.t('validation.accepted');
  }

  static validationEmail (email) {
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email) || i18n.global.t('validation.invalid-email');
  }

  static validationPasswordMin (password) {
    return password.length >= 8 || i18n.global.t('validation.password-min');
  }

  static validationPasswordLetters (password) {
    const regex = /(?=.*[A-Z])(?=.*[a-z])/;
    return regex.test(password) || i18n.global.t('validation.password-upper-and-lower');
  }

  static validationPassword (password) {
    const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[@$!%*#?&123456789]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return passwordRegex.test(password) || i18n.global.t('validation.invalid-password');
  }

  static validationConfirmCode (code) {
    return code.length === 5 || i18n.global.t('validation.code-length');
  }

  static validationPasswordResetCode (code) {
    return code.length === 5 || i18n.global.t('validation.code-length');
  }

  static validationNumber (number) {
    return !isNaN(number) || i18n.global.t('validation.number');
  }

  static validationFloat (number) {
    return (!isNaN(number) && number % 1 !== 0) || i18n.global.t('validation.float');
  }

  static validationFloatOrInt (number) {
    return !isNaN(number) || i18n.global.t('validation.number');
  }

  static validationDurationBan (number) {
    return (number > -2 && number <= 5000) || i18n.global.t('validation.duration-ban');
  }

  static validationLength250 (text) {
    return text.length <= 250 || i18n.global.t('validation.toLong250');
  }

  static validationLength8 (text) {
    return text.length <= 8 || i18n.global.t('validation.toLong8');
  }

  static createTitle (model) {
    return i18n.global.t(model) + ' ' + i18n.global.t('dialog.createTitle');
  }

  static editTitle (model) {
    return i18n.global.t(model) + ' ' + i18n.global.t('dialog.editTitle');
  }

  static deleteTitle (model) {
    return i18n.global.t(model) + ' ' + i18n.global.t('dialog.deleteTitle');
  }

  static deleteText (model) {
    return i18n.global.t('dialog.deleteText') + ' ' + i18n.global.t(model) + ' ' + i18n.global.tc('dialog.deleteText', 3);
  }

  static backendTranslation (translation) {
    const { fallbackLanguage, translations } = translation;
    const locale = i18n.global.locale;
    let value = fallbackLanguage.name;

    for (const translation of translations) {
      if (translation.key === locale) {
        value = translation.value;
        break;
      }
    }

    return value;
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          utils: Utils,
        };
      },
    });
  },
};
