<script setup>
import { useStore } from 'vuex';
import ErrorDialog from '@/components/form/ErrorDialog';
import { computed, onMounted, ref, watch } from 'vue';
import i18n from '@/i18n';
import SuccessDialog from '@/components/form/SuccessDialog.vue';
import CookieDialog from '@/components/form/CookieDialog.vue';
import BannerElement from '@/components/BannerElement.vue';
import TeamNavigator from '@/components/TeamNavigator.vue';
import { useTheme } from 'vuetify';

const store = useStore();
const theme = useTheme();

const drawer = ref(false);
const error = computed(() => store.getters.error);
const success = computed(() => store.getters.success);
const locale = computed(() => store.getters.locale);
const isTeam = computed(() => store.getters.isTeam);

const scroll = ref(0);

addEventListener('scroll', () => {
  scroll.value = scrollY;
});

const toggleTheme = () => {
  if (theme.global.name.value === 'dark') theme.global.name.value = 'light';
  else theme.global.name.value = 'dark';
};

const errorShowing = computed({
  get () {
    return store.getters.errorShowing;
  },
  set (val) {
    store.commit('setErrorShowing', val);
  },
});
const successShowing = computed({
  get () {
    return store.getters.successShowing;
  },
  set (val) {
    store.commit('setSuccessShowing', val);
  },
});

const setLang = (locale) => {
  document.documentElement.setAttribute('lang', locale);
};

watch(locale, (newVal) => {
  setLang(newVal);
});

onMounted(() => {
  if (locale.value) {
    i18n.global.locale = locale.value;
  } else {
    setLang(i18n.global.locale);
  }
});

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  theme.global.name.value = 'dark';
}

const links = [
  { title: 'home.home', path: '/', icon: 'home' },
  { title: 'cards.title', path: '/karten/info', icon: 'list-box', menu: true, items: [{ title: 'cards.info.title', path: '/karten/info' }, { title: 'cards.services.title', path: '/karten/services' }] },
];
</script>

<template>
  <v-app id="v-app">
    <v-app-bar :style="scroll === 0 && $route.meta.hideTop ? 'box-shadow: none' : ''" :color="scroll === 0 && $route.meta.hideTop ? 'transparent' : 'on-surface'">
      <img :src="utils.getImgUrl('logo.png')" class="ml-3 clickable small-image" @click="$router.push('/')" alt="logo">
      <v-toolbar-title
          v-if="scroll !== 0 || $route.path !== '/'"
          class="brand clickable"
          style="font-size: 1.3rem"
          @click="$router.push('/')"
      >
        <span class="color-primary">{{ $t('company.main') }}</span>
      </v-toolbar-title>
      <v-spacer/>
      <div class="gt1035 mr-3 rounded d-flex flex-row" style="background-color: rgb(var(--v-theme-onsurface))">
        <div v-for="(link, i) in links" :key="i" class="">
          <v-btn
              v-if="!link.menu"
              color="black"
              variant="plain"
              style="font-size: 1rem"
              class="font-weight-bold mx-1"
              :to="link.path"
          >
            {{ $t(link.title) }}
          </v-btn>
          <v-menu v-else open-on-hover offset-y transition="slide-x-transition" bottom right>
            <template v-slot:activator="{ props }">
              <v-btn
                  flat
                  v-bind="props"
                  color="black"
                  variant="plain"
                  style="font-size: 1rem"
                  class="font-weight-bold mx-1"
              >
                {{ $t(link.title) }}<v-icon icon="mdi-menu-down" size="xl-small"></v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in link.items" :key="index" router :to="item.path">
                <v-list-item-action>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
<!--        <span class="mx-2">|</span>-->
<!--        <locale-switcher/>-->
      </div>
      <div class="st1035 mr-1 rounded" style="background-color: rgb(var(--v-theme-onsurface))">
<!--        <locale-switcher/>-->
<!--        <span class="mx-2">|</span>-->
        <v-app-bar-nav-icon aria-label="Menu" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <team-navigator v-if="isTeam" />
    <v-navigation-drawer
        v-model="drawer"
        temporary
        location="right"
    >
      <v-list density="compact" nav aria-label="page">
        <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :to="link.path"
            role="option"
            :aria-label="link.name"
            :prepend-icon="`mdi-${link.icon}`"
            :title="$t(link.title)"
            :value="$t(link.title)"
        />
      </v-list>
    </v-navigation-drawer>
    <v-card elevation="0" color="transparent">
      <v-layout>
        <v-main>
          <banner-element class="mb-3" v-if="$route.meta.banner ?? true" style="box-shadow: inset 0 -3px 3px rgb(var(--v-theme-surface));"/>

          <router-view class="mt-3"/>
          <v-footer color="transparent">
            <v-row justify="center" no-gutters>
              <v-col class="text-center mt-4" cols="12">
                © {{ new Date().getFullYear() }}, <strong><span class="color-primary">{{ $t('company.main') }}</span></strong>
              </v-col>
              <v-chip-group>
                <v-chip class="color-primary" @click="$router.push('/privacy')">{{ $t('titles.Privacy') }}</v-chip>
                <v-chip class="color-primary" @click="$router.push('/imprint')">{{ $t('titles.Imprint') }}</v-chip>
              </v-chip-group>
              <v-col cols="12" class="text-center">
                <v-btn
                    style="font-size: 0.6rem"
                    variant="plain"
                    color="grey"
                    to="/auth/login"
                >{{ $t('home.workerarea') }}</v-btn>
              </v-col>
            </v-row>
          </v-footer>
          <error-dialog v-model="errorShowing" v-if="error" :title="error.title" :text="error.text" />
          <success-dialog v-model="successShowing" v-if="success" :title="success.title" :text="success.text" />
          <cookie-dialog/>
        </v-main>
      </v-layout>
    </v-card>
    <v-icon icon="mdi-theme-light-dark" id="themeButton" @click="toggleTheme" />
  </v-app>
</template>

<style>
h1, h2, h3, h4, h5 {
  color: rgb(var(--v-theme-primary)) !important;
}

.v-expansion-panel {
  background-color: rgb(var(--v-theme-dark-surface)) !important;
}

.color-primary {
  color: rgb(var(--v-theme-primary)) !important;
}

.color-secondary {
  color: rgb(var(--v-theme-secondary)) !important;
}

.color-garden {
  color: rgb(var(--v-theme-garden)) !important;
}

.color-cards {
  color: rgb(var(--v-theme-cards)) !important;
}

.color-security {
  color: rgb(var(--v-theme-security)) !important;
}

.color-on-primary {
  color: rgb(var(--v-theme-on-primary)) !important;
}

.bg-on-surface {
  background-color: rgb(var(--v-theme-onsurface)) !important;
}

.sm-view {
  max-width: 800px !important;
  margin: auto;
}

.m-view {
  max-width: 1200px !important;
  margin: auto;
}

.xm-view {
  max-width: 1600px !important;
  margin: auto;
}

.v-dialog .v-overlay__content {
  margin: 0 !important;
}

.lg-padding {
  padding-right: 15px;
  padding-left: 15px;
}

.subtitle {
  opacity: 1;
  -webkit-line-clamp: unset !important;
}

.clickable {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .lg-padding {
    padding-right: 0;
    padding-left: 0;
  }
}

</style>

<style scoped>
#themeButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}

.brand {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

@media screen and (min-width: 1035px) {
  .st1035 {
    display: none;
  }
}
@media screen and (max-width: 1035px) {
  .gt1035 {
    display: none;
  }
}

::-webkit-scrollbar { width: 0; height: 0;}
</style>
