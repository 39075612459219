const Login = () => import('@/views/auth/Login');
const ResetPassword = () => import('@/views/auth/ResetPassword');

const routes = [
  {
    path: 'login',
    name: 'AuthLogin',
    component: Login,
  },
  {
    path: 'reset-password',
    name: 'AuthResetPassword',
    component: ResetPassword,
    meta: {
      requiresNotLoggedIn: true,
    },
  },
];

export default routes;
